import API from "lib/api";
import apiRoutes from "lib/api/apiRoutes";
import { useEffect, useState } from "react";
import FileUploadWrapperLoader from "./FileUploadWrapperLoader/FileUploadWrapperLoader";
import { dataQueryStatus } from "utils/formatHandlers";
import { getErrorMessage } from "utils/helper";

const { LOADING, ERROR, DATAMODE } = dataQueryStatus;

const FileUploadWrapper = ({
    file,
    setLoading,
    setError,
    setUploadedFileKey,
    useLoader,
    loaderClass,
    children,
    setStatus,
}) => {
    const { file: originalFile } = file || {};
    const { name } = originalFile || {};
    const [isUploading, setIsUploading] = useState(false);
    const generateUploadLink = async () => {
        try {
            setLoading?.(true);
            setError?.();
            setIsUploading(true);
            setStatus?.(LOADING);
            const url = apiRoutes.generateUploadLink;

            const res = await API.put(
                url,
                {
                    file: name,
                },
                {}
            );

            const data = res.data;
            if (res.status === 200) {
                const { fileKey, link } = data?.data || {};
                uploadFile(link, fileKey);
                setStatus?.(DATAMODE);
            }
        } catch (err) {
            setLoading?.(false);
            setError?.(getErrorMessage(err));
            setIsUploading(false);
            setStatus?.(ERROR);
        }
    };

    const uploadFile = async (url, fileKey) => {
        try {
            setLoading?.(true);
            setIsUploading(true);
            setStatus(LOADING);
            const options = {
                disableAuthorization: true,
            };

            const reader = new FileReader();
            reader.readAsArrayBuffer(originalFile);
            reader.onload = async () => {
                const binaryData = reader.result;
                const res = await API.put(`${url}`, binaryData, options);

                if (res.status === 200) {
                    setUploadedFileKey?.(fileKey, file);
                    setLoading?.(false);
                    setError?.();
                    setIsUploading(false);
                    setStatus(DATAMODE);
                }
            };
        } catch (err) {
            setError?.(getErrorMessage(err));
            setLoading?.(false);
            setIsUploading(false);
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        if (name) {
            generateUploadLink();
        }
        // eslint-disable-next-line
    }, [name]);

    return (
        <>
            {useLoader ? (
                <FileUploadWrapperLoader
                    {...{ isUploading, loaderClass, children }}
                />
            ) : (
                children
            )}
        </>
    );
};

export default FileUploadWrapper;
