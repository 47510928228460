import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import imageLinks from "../../../../../assets/images";
import { SocketContext } from "../../../../../lib/socket/context/socket";
import { IS_NOT_TYPING, IS_TYPING } from "../../../../../lib/socket/events";
import { Button } from "../../../../ui";
import { useIsTyping } from "use-is-typing";
import PoweredBy from "../../../../common/PoweredBy/PoweredBy";
import UploadIcons from "./UploadIcons/UploadIcons";
import { dataQueryStatus } from "../../../../../utils/formatHandlers";
import {
    expandKeyboardTextArea,
    isDeviceMobileTablet,
    isIOSDevice,
    removeMobileKeyboard,
    scrollChatToBottom,
    showIphoneKeyboard,
} from "../../../../../utils/helper";
import { INPUT_NEEDED, formInputTypes } from "../MessageBody/Messages/enums";
import { IMAGE } from "./UploadIcons/enum";
import "./LiveChatInput.scss";
import UploadedFiles from "./UploadIcons/UploadedFiles/UploadedFiles";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import { datePickerStages } from "./DatePickerWrapper/enum";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import { useWindowSize } from "utils/hooks";
import FileUploadWrapper from "components/common/FileUploadWrapper/FileUploadWrapper";
import LiveChatInputTypes from "./LiveChatInputTypes/LiveChatInputTypes";
import LiveChatInputButton from "./LiveChatInputButton/LiveChatInputButton";

const { DATE_VALUE, PICK_DATE } = datePickerStages;
const { LOADING, ERROR, DATAMODE } = dataQueryStatus;
const { NUMERIC, MULTISELECT } = formInputTypes;

const { RELAXED } = defaultTemplates;

const LiveChatInput = ({
    handleNewMessage,
    ticketId,
    fetchingInputStatus,
    allowUserInput,
    inputType,
    currentFormElement,
    disableInput,
    uploads,
    updateUploads,
    isDateFormElement,
    mssgSendStatus,
    messages,
    trackUserIsTyping,
    setInputFocus,
}) => {
    const [isTyping, inputRef] = useIsTyping();
    const inputContainerRef = useRef();

    const [selectedMedia, setSelectedMedia] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState("");
    const [status, setStatus] = useState();
    const [showModal, toggleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [datePickerStage, setDatePickerStage] = useState(DATE_VALUE);

    const isDisabled = fetchingInputStatus || !allowUserInput;

    const [request, updateRequest] = useState({
        message: "",
        fileAttachments: [
            {
                fileAttachmentUrl: "",
                fileAttachmentType: "",
                fileAttachmentName: "",
            },
        ],
    });

    const handleFileUpload = (fileUrl, fileType, fileName) => {
        updateRequest({
            ...request,
            fileAttachments: [
                {
                    fileAttachmentUrl: fileUrl,
                    fileAttachmentType: fileType,
                    fileAttachmentName: fileName,
                },
            ],
        });
    };

    const socket = useContext(SocketContext);

    const clearUserInput = () => {
        updateRequest({
            message: "",
            fileAttachments: [
                {
                    fileAttachmentUrl: "",
                    fileAttachmentType: "",
                    fileAttachmentName: "",
                },
            ],
        });
        updateUploads([]);
        setDatePickerStage(DATE_VALUE);
        setLoading(false);
    };

    const handleRemoveFile = (fileName, fileIndex) => {
        updateUploads([]);

        updateRequest((prev) => ({
            ...prev,
            fileAttachments: [],
        }));

        setErrors((prev) => ({ ...prev, file: "" }));

        setLoading(false);
    };

    const sendNewMessage = () => {
        if (
            request?.fileAttachments[0]?.fileAttachmentUrl ||
            isDateFormElement ||
            isFormElementMultiselect ||
            request?.message?.length > 0
        ) {
            setLoading(true);
        } else {
            setLoading(false);
            return;
        }

        handleNewMessage(request, clearUserInput);
        setErrors((prev) => ({ ...prev, file: "" }));
    };

    const handleAndroidKeyboard = () => {
        window.addEventListener("resize", (e) => {
            const messageBody = document.getElementById("messageBody");
            messageBody.style.scrollBehavior = "smooth";
            messageBody.scrollTop = messageBody.scrollHeight;
        });
    };

    const resetInput = () => {
        const textAreaField = document.getElementById("form__input");
        const textAreaFieldContainer =
            document.getElementById("chat__input--group");
        textAreaFieldContainer.style.height = "58px";
        textAreaFieldContainer.style.borderRadius = "100px";
        textAreaField.style.height = "58px";
    };

    const textFieldHeightRef = useRef(0);

    const handleInputFocus = () => {
        setInputFocus?.(true);
        scrollChatToBottom();
        if (isIOSDevice()) {
            showIphoneKeyboard();
        } else {
            if (isDeviceMobileTablet()) {
                handleAndroidKeyboard();
            }
        }
    };

    const handleInputBlur = () => {
        setInputFocus?.(request?.message?.length > 0 ? true : false);
        return;
    };

    const handleTyping = (e) => {
        trackUserIsTyping();
        scrollChatToBottom();
        //    if (document.activeElement.id === "send__button") {
        //     document.activeElement.click();
        // }
        let { value } = e.target;
        if (inputType === NUMERIC) {
            value = value.replace(/\D/g, "");
        }
        updateRequest({ ...request, message: value });
        setInputFocus(true);
    };

    useEffect(() => {
        const decidedEvent = isTyping ? IS_TYPING : IS_NOT_TYPING;
        socket.emit(decidedEvent, { ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTyping]);

    useEffect(() => {
        return () => {
            socket.emit(IS_NOT_TYPING, { ticketId });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDatePickerStage = () => {
        setDatePickerStage(DATE_VALUE);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendNewMessage();
        setInputFocus?.(false);
        resetInput();
    };

    const handleOnDoneEvent = (e) => {
        const activeElementId = document.activeElement?.id;
        if (
            isIOSDevice() &&
            !(
                activeElementId === "send_button" ||
                activeElementId !== "form__input"
            ) &&
            e?.key === undefined
        ) {
            removeMobileKeyboard();
        }
    }

    const onEnterPress = (e) => {
        if(e.keyCode === 13) {
            handleSubmit(e);
        }else if(e.keyCode === 13 && e.metaKey){
            e.preventDefault()
        }
    }
    const btnDisabled =
        uploads?.length > 0
            ? status === LOADING || status === ""
            : isDisabled || request?.message === "";

    const { defaultTemplate } = useSelector(
        (state) => state?.chat?.chatSettings
    );

    const isRelaxedTemplate = defaultTemplate === RELAXED;

    const { formElementType } = currentFormElement || {};

    const isFormElementImage = formElementType === IMAGE;

    const isFormElementMultiselect = formElementType === MULTISELECT;

    const isFinalDatePickerStage = datePickerStage === PICK_DATE;

    const { width } = useWindowSize();

    const isTablet = width <= 768;

    const lastMessage = messages[messages.length - 1];

    const userInstructionLabel =
        uploads?.length > 0
            ? null
            : lastMessage?.branchOptions?.length > 0
            ? "Choose an option above"
            : lastMessage?.messageType === "ACTION_INFO"
            ? lastMessage?.messageActionType === INPUT_NEEDED
                ? "Please we need a response from you"
                : "Please wait for a response from us"
            : null;

    useEffect(() => {
        // if it is button and type submit, then call removeMobileKeyboard
        // else if and if it is not input, then call removeMobileKeyboard
        document?.body?.addEventListener?.("click", () => {
            const activeElementId = document.activeElement?.id;
            if (
                activeElementId === "send_button" ||
                activeElementId !== "form__input"
            ) {
                removeMobileKeyboard();
            }
        });

        return () => document?.body?.removeEventListener?.("click", () => {});

        // eslint-disable-next-line
    }, [document, window]);

    useEffect(() => {
        expandKeyboardTextArea(textFieldHeightRef);
        // eslint-disable-next-line
    }, [request?.message]);

    useEffect(() => {
        // alert(`${window.innerHeight}px`)
        const textAreaField = document.getElementById("form__input");

        textAreaField?.addEventListener("blur", handleOnDoneEvent);
        textAreaField?.addEventListener("focusout", (e) => {
            if (
                isIOSDevice()
            ) {
                removeMobileKeyboard();
            }
        });

    }, []);

    return (
        <div className={`chat__input__wrapper`}>
            <div
                id='inputGroup'
                className={`col-md-10 col-12 ${
                    !allowUserInput && isRelaxedTemplate
                        ? "disallowed__section"
                        : ""
                }`}
                title={!allowUserInput ? "Not Allowed" : "Type a message"}
                ref={inputContainerRef}>
                <form
                    onSubmit={handleSubmit}
                    id='chatInput'
                    className={`
                ${isFormElementMultiselect ? "chatInput-form-select" : ""}
                ${
                    isTablet && uploads.length > 0 ? "chatInputFormSubmit" : ""
                }`}>
                    <div
                        className={
                            isFormElementMultiselect
                                ? ""
                                : "chat__input--container"
                        }>
                        <div
                            className={`chat__input--group ${
                                !allowUserInput && !isRelaxedTemplate
                                    ? "chat__input--group--workmode__disabled"
                                    : ""
                            }`}
                            id='chat__input--group'>
                            {uploads?.length === 0 &&
                            (isFormElementImage ||
                                isFinalDatePickerStage ||
                                isFormElementMultiselect) ? (
                                <>
                                    {isDateFormElement ||
                                    isFormElementMultiselect ? (
                                        <LiveChatInputTypes
                                            {...{
                                                currentFormElement,
                                                inputType,
                                                datePickerStage,
                                                request,
                                                updateRequest,
                                                disableInput,
                                                setDatePickerStage,
                                                loading,
                                                isRelaxedTemplate,
                                                handleTyping,
                                                handleInputBlur,
                                                handleInputFocus,
                                                inputRef,
                                                isDisabled,
                                                onEnterPress
                                            }}
                                        />
                                    ) : (
                                        uploads?.length === 0 && (
                                            <UploadIcons
                                                upload={uploads}
                                                updateUpload={updateUploads}
                                                isDisabled={isDisabled}
                                                setErrors={setErrors}
                                                showModal={showModal}
                                                toggleModal={toggleModal}
                                                selectedMedia={selectedMedia}
                                                icon={
                                                    imageLinks?.svg?.attachment2
                                                }
                                                currentFormElement={
                                                    currentFormElement
                                                }
                                                label={"Upload File"}
                                            />
                                        )
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className='chat__input--group--inputs'>
                                        {uploads?.length === 0 ? (
                                            <>
                                                {userInstructionLabel &&
                                                !isRelaxedTemplate ? (
                                                    <div className='chat__input--group__choose-option'>
                                                        {mssgSendStatus ===
                                                        LOADING ? (
                                                            <SmallLoader otherClassName='instruction__label__loader' />
                                                        ) : (
                                                            userInstructionLabel
                                                        )}
                                                    </div>
                                                ) : (
                                                    <LiveChatInputTypes
                                                        {...{
                                                            currentFormElement,
                                                            inputType,
                                                            datePickerStage,
                                                            request,
                                                            updateRequest,
                                                            disableInput,
                                                            setDatePickerStage,
                                                            loading,
                                                            isRelaxedTemplate,
                                                            handleTyping,
                                                            handleInputBlur,
                                                            handleInputFocus,
                                                            inputRef,
                                                            isDisabled,
                                                            onEnterPress
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <FileUploadWrapper
                                                setStatus={setStatus}
                                                setError={setErrorMssg}
                                                file={uploads?.[0]}
                                                setUploadedFileKey={(
                                                    fileKey,
                                                    file
                                                ) =>
                                                    handleFileUpload(
                                                        fileKey,
                                                        file?.fileAttachmentType,
                                                        file?.fileAttachmentName
                                                    )
                                                }>
                                                <UploadedFiles
                                                    uploads={uploads}
                                                    status={status}
                                                    handleRemoveFile={
                                                        handleRemoveFile
                                                    }
                                                    icon={
                                                        imageLinks?.svg
                                                            ?.attachment2
                                                    }
                                                    handleRetry={(file) => {
                                                        updateUploads([]);
                                                        updateUploads([file]);
                                                    }}
                                                    maximize={(
                                                        fileAttachmentType,
                                                        fileAttachmentName,
                                                        fileAttachmentUrl
                                                    ) => {
                                                        setSelectedMedia({
                                                            fileAttachmentType,
                                                            fileAttachmentName,
                                                            fileAttachmentUrl,
                                                        });
                                                        toggleModal(true);
                                                    }}
                                                    disableClick={
                                                        status !== DATAMODE
                                                    }
                                                />
                                            </FileUploadWrapper>
                                        )}
                                    </div>
                                    {isDisabled && !isRelaxedTemplate ? (
                                        ""
                                    ) : uploads?.length > 0 && isTablet ? (
                                        ""
                                    ) : userInstructionLabel ? (
                                        ""
                                    ) : (
                                        <Button
                                            type='submit'
                                            text={"Send"}
                                            icon={
                                                <ReactSVG
                                                    src={imageLinks?.svg?.send}
                                                />
                                            }
                                            classType='default'
                                            id='send__button'
                                            otherClass={`send__button ${
                                                !btnDisabled ? "active" : ""
                                            } ${
                                                mssgSendStatus === LOADING
                                                    ? "active"
                                                    : ""
                                            }`}
                                            loading={
                                                isFinalDatePickerStage
                                                    ? disableInput
                                                    : mssgSendStatus === LOADING
                                            }
                                            disabled={
                                                (btnDisabled ||
                                                    fetchingInputStatus ||
                                                    status === LOADING) &&
                                                !(mssgSendStatus === ERROR)
                                            }
                                            loaderClassName='send__button__loader'
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {(errors?.file || errorMssg) && (
                        <p className='file__error'>
                            {errors?.file || errorMssg}
                        </p>
                    )}
                    {mssgSendStatus === ERROR && (
                        <div className='chat__input__error'>
                            Network connection failed. <b>Tap to retry</b>
                        </div>
                    )}

                    <LiveChatInputButton
                        hasUploads={uploads?.length > 0}
                        {...{
                            isDateFormElement,
                            isFormElementImage,
                            isTablet,
                            isRelaxedTemplate,
                            isFinalDatePickerStage,
                            isFormElementMultiselect,
                            disableInput,
                            status,
                            mssgSendStatus,
                            btnDisabled,
                            handleDatePickerStage,
                            fetchingInputStatus,
                            loading,
                        }}
                    />
                </form>
                <PoweredBy />
            </div>
        </div>
    );
};

export default LiveChatInput;
